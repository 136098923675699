/* ------------------------------------------------------------------------- 
SUMMARY 
------------------------------------------------------------------------- 
0. GENERAL TEXTS
1. NAVBAR
2. BUTTONS
3. TESTIMONIAL
*/


/* ------------------------------------------------------------------------- 
0. GENERAL TEXTS
------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap');


:root {
    font-size: 14px;
}


h1 {
    font-family: $font-family-titles;
    font-weight: 500;
}

h2 {
    font-family: $font-family-titles;
}

h3 {
    font-weight: 500;
}

p {

    font-size: 1.15rem;
}
