/* ------------------------------------------------------------------------- 
0. COLORS 
------------------------------------------------------------------------- */

// Light theme

//General colors
$bg-color-light: #D8410B;
$bg-color-light-2: rgba(255, 128, 83, .70);
$navbar-bg-light: rgba(190, 50, 0, .50);

//texts colors
$text-color-light: #C8EDFF;
$text-color-dark-ligh: #0F5376;

//Font style
$text-strong: 700;


/* ------------------------------------------------------------------------- 
1. ANIMATIONS 
------------------------------------------------------------------------- */

$transition-smooth: .2s;


/* ------------------------------------------------------------------------- 
2. FONT-FAMILY
------------------------------------------------------------------------- */


$font-family-titles:'Fira Code', monospace;