* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100vh;
    width: 100%;
    scroll-behavior: smooth;

    body {
        height: inherit;
        width: inherit;

    }
}
