/* ------------------------------------------------------------------------- 
0. General buttons
------------------------------------------------------------------------- */

// Inner border radius = innerRadius + distance = outerRadius
.outer {
    border-radius: 20px;
}

.inner {
    border-radius: 12px;
}

.btn {
    padding: 10px 25px;
    transition: $transition-smooth;
    color: $text-color-light;
    font-size: 1.1rem;

    &:hover {
        cursor: pointer;
    }
}

/* ------------------------------------------------------------------------- 
1. Switch Btn
------------------------------------------------------------------------- */

.switch {

    bottom: 4rem;
    border: 4px solid #64ffda;
    border-radius: 25px;
    height: 60px;
    left: 0.7rem;
    width: 34px;
    display: flex;
    position: relative;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 1px;
  bottom: 1px;
  background-color: #b1b1b1;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #b3efbf;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateY(26px);
  -ms-transform: translateY(26px);
  transform: translateY(-26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.vertical-text {
    writing-mode: vertical-rl;
    text-orientation: upright;
}